import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import "../../styles/ChatInterface.css";
import { getAIModels } from "../../util/db";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
  getFirestore,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { firebaseApp } from "../../util/firebase";
import { useAuth } from "../../util/auth";
import { Menu, Send } from "lucide-react";
import { chatApiRequest } from "../../util/util";
import { useRouter } from "../../util/router";

export default function ChatInterface() {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [aiModels, setAiModels] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]); // State to hold messages
  const db = getFirestore(firebaseApp);
  const auth = useAuth();
  const router = useRouter();
  const [isImageDisplayVisible, setIsImageDisplayVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSubsModalOpen, setIsSubsModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const demoModels = [
    {
      id: "7ueKaiVNLdNZLIb1nlkb",
      characterId: "char1",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/xgenerator-project.appspot.com/o/chat-char%2FCapture-2025-01-08-115917.png?alt=media&token=2417ec2d-b02d-4931-89a1-eb1e9923e2d1",
      name: "Zara",
      description:
        "Zara is fiery salsa instructor by day, nightlife queen by night. Her curves don’t lie, and neither does her flirtatious attitude. With her deep brown eyes and daring outfits, she’s always in control. She’ll challenge you to keep up with her fast-paced energy and sultry moves. One dance with Zara, and you’ll be hooked on her wild, untamed passion.",
    },
    {
      id: "BEWeT71DvW1ybvFDUxYP",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/xgenerator-project.appspot.com/o/chat-char%2FCapture-2025-01-08-120232.png?alt=media&token=d1861472-36e8-417d-9a12-6bdbaeeebbd8",

      characterId: "char2",
      name: "Layla",
      description:
        "Layla is a high-end cocktail waitress in a swanky rooftop bar. She is the ultimate tease, with her luscious brunette hair and a body that demands attention. She’s all about playful smirks, lingering touches, and sly innuendos. She loves the thrill of being chased and knows exactly how to keep you wanting more. Think you can handle her?",
    },
    {
      id: "HOJwL95DDCYtG7RW0Jn7",
      name: "Aria",
      characterId: "char3",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/xgenerator-project.appspot.com/o/chat-char%2FWhatsApp%20Image%202025-01-08%20at%2012.16.18.jpeg?alt=media&token=4a3d5baa-2183-4572-9641-04731f1b9a6a",
      description:
        "Aria is a seductive lounge singer with a voice as sweet as sin. Her sultry gaze and velvet voice will leave you begging for an encore. Her hourglass figure and low-cut dresses make her the center of attention. She’s a master of subtle seduction, drawing you in with every whispered word. Get closer, and you might just hear her sing just for you.",
    },
  ];

  const fetchAIModels = async () => {
    try {
      const storedAiModels = JSON.parse(localStorage.getItem("aiModels"));

      if (storedAiModels && storedAiModels.length > 0) {
        setAiModels(storedAiModels);
        const storedActiveChat = JSON.parse(localStorage.getItem("activeChat"));
        if (storedActiveChat) {
          setActiveChat(storedActiveChat);
        } else {
          setActiveChat(storedAiModels[0]);
        }
      } else {
        // Fetch from database if not in local storage
        const models = await getAIModels();
        setAiModels(models);
        localStorage.setItem("aiModels", JSON.stringify(models));
        if (models.length > 0) {
          setActiveChat(models[0]);
          localStorage.setItem("activeChat", JSON.stringify(models[0]));
        }
      }
    } catch (error) {
      console.error("Error fetching AI models:", error);
    }
  };

  const fetchMessages = async () => {
    if (activeChat) {
      try {
        const userId = auth?.user?.id;
        const chatRef = collection(
          db,
          `users/${userId}/chats`,
          activeChat.id,
          "messages"
        );

        // Sorting the messages by timestamp in descending order
        const querySnapshot = await getDocs(chatRef);
        const fetchedMessages = [];

        querySnapshot.forEach((doc) => {
          fetchedMessages.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        // Sort messages by timestamp in descending order
        fetchedMessages.sort((a, b) => {
          return b.timestamp.seconds - a.timestamp.seconds;
        });

        setMessages(fetchedMessages);

        // Handle the last message timestamp and update the AI models
        let lastMessage = null;
        let lastTimestamp = null;

        querySnapshot.forEach((doc) => {
          const messageData = doc.data();
          if (
            !lastTimestamp ||
            messageData.timestamp.seconds > lastTimestamp.seconds
          ) {
            lastMessage = messageData;
            lastTimestamp = messageData.timestamp;
          }
        });

        if (lastMessage) {
          setAiModels((prevModels) => {
            return prevModels.map((chat) => {
              if (chat.id === activeChat.id) {
                return {
                  ...chat,
                  lastMessage: lastMessage.content,
                  lastTimestamp: lastTimestamp,
                };
              }
              return chat;
            });
          });
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  useEffect(() => {
    // Load AI models or demo models based on authentication state
    if (auth?.user?.planIsActive) {
      // Fetch actual AI models if logged in
      fetchAIModels();
      fetchMessages();
    } else {
      setAiModels(demoModels);
      setActiveChat(demoModels[0]); // Set default active chat
      setMessages([]);
    }
  }, [db, auth]);

  useEffect(() => {
    // Load data from localStorage on mount
    const savedModels = localStorage.getItem("aiModels");
    const savedActiveChat = localStorage.getItem("activeChat");
    const savedMessages = localStorage.getItem("messages");

    if (savedModels) {
      const parsedModels = JSON.parse(savedModels);
      setAiModels(parsedModels);

      if (savedActiveChat) {
        const parsedActiveChat = JSON.parse(savedActiveChat);
        setActiveChat(parsedActiveChat);

        if (savedMessages) {
          const parsedMessages = JSON.parse(savedMessages);
          setMessages(parsedMessages);
        }
      } else if (parsedModels.length > 0) {
        setActiveChat(parsedModels[0]);
      }
    }
  }, []);

  useEffect(() => {
    // Save aiModels and activeChat to localStorage whenever they change
    localStorage.setItem("aiModels", JSON.stringify(aiModels));
    localStorage.setItem("activeChat", JSON.stringify(activeChat));
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [aiModels, activeChat, messages]);

  const sendMessage = async () => {
    if (!messageInput.trim() || !activeChat) return;

    if (!auth?.user) {
      // alert("Please sign in to send messages.");
      setIsSignInModalOpen(true);
      // router.push("/auth/signin"); // Redirect to sign-in page
      return;
    }

    if (!auth?.user?.planIsActive) {
      // alert("Please subscribe to send messages.");
      setIsSubsModalOpen(true);
      // router.push("/pricing"); // Redirect to sign-in page
      return;
    }

    try {
      const userId = auth?.user?.id;
      const chatRef = doc(
        collection(db, `users/${userId}/chats`),
        activeChat.id
      );

      // Check if the chat exists; if not, create it
      const chatData = {
        characterId: activeChat.id,
        characterName: activeChat.name,
        timestamp: serverTimestamp(),
      };
      await setDoc(chatRef, chatData, { merge: true });

      // Add the message to the `messages` subcollection
      const messagesRef = collection(chatRef, "messages");
      const newMessage = await addDoc(messagesRef, {
        content: messageInput,
        timestamp: serverTimestamp(),
        senderId: userId,
        senderName: auth.user.name,
        sender: "user",
      });

      // Optimistic UI update
      setMessages((prevMessages) => [
        {
          id: newMessage.id,
          content: messageInput,
          sender: "user",
          senderName: auth.user.name,
        },
        ...prevMessages,
      ]);

      setMessageInput("");

      const inputData = {
        message: messageInput,
        characterId: activeChat?.id,
      };

      setIsLoading(true);

      const response = await chatApiRequest("chat", "POST", inputData);

      if (response) {
        setMessages((prevMessages) => [
          {
            id: Date.now() + 1,
            content: response[0]?.message.content.message,
            sender: "assistant",
            senderName: activeChat?.name,
          },
          ...prevMessages,
        ]);

        await addDoc(messagesRef, {
          content: response[0]?.message.content.message,
          timestamp: serverTimestamp(),
          senderId: activeChat?.id,
          senderName: activeChat?.name,
          sender: "assistant",
        });

        // Update lastMessage and lastTimestamp for the active chat
        setAiModels((prevModels) =>
          prevModels.map((model) =>
            model.id === activeChat.id
              ? {
                  ...model,
                  lastMessage: response[0]?.message.content.message,
                  lastTimestamp: { seconds: Date.now() / 1000 },
                }
              : model
          )
        );
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false); // Set loading to false when done
    }
  };

  useEffect(() => {
    setAiModels((prevModels) =>
      [...prevModels].sort((a, b) => {
        const aTime = a.lastTimestamp?.seconds || 0;
        const bTime = b.lastTimestamp?.seconds || 0;
        return bTime - aTime;
      })
    );
  }, [messages]);

  useEffect(() => {
    fetchMessages();
  }, [activeChat, db, auth]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  const toggleImageDisplay = () => {
    setIsImageDisplayVisible((prev) => !prev);
  };

  const handleDivClick = (chat) => {
    setIsSidebarExpanded(!isSidebarExpanded);
    setActiveChat(chat);
  };

  const resetChat = async () => {
    if (!activeChat) return;

    try {
      const userId = auth?.user?.id;
      const chatRef = collection(
        db,
        `users/${userId}/chats`,
        activeChat.id,
        "messages"
      );
      const querySnapshot = await getDocs(chatRef);

      // Delete each message
      const deletePromises = querySnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);

      setMessages([]);
      localStorage.removeItem("aiModels");
      localStorage.removeItem("activeChat");
      localStorage.removeItem("messages");
    } catch (error) {
      console.error("Error resetting chat:", error);
    }
  };

  const handleFeedbackSubmit = async () => {
    if (!feedbackMessage) {
      alert("Feedback message cannot be empty.");
      return;
    }

    try {
      const serviceId = "service_ojycimo";
      const templateId = "template_mnpbphy";
      const userId = "waqQw4LC4iNqY3Ztm";

      const emailData = {
        message: feedbackMessage,
        from_name: auth?.user?.name,
        from_email: auth?.user?.email,
      };

      const response = await emailjs.send(
        serviceId,
        templateId,
        emailData,
        userId
      );

      console.log(response, "resssssssss");

      if (response.status === 200) {
        setIsModalOpen(false);
        setFeedbackMessage("");
      } else {
        throw new Error("EmailJS response error");
      }
    } catch (error) {
      console.error("Error submitting feedback via EmailJS:", error);
    }
  };

  return (
    <div style={{ borderTop: "1px solid #333" }}>
      <div className="chat-container responsive-layout">
        <div className="chat-wrapper">
          {/* Sidebar */}
          <div className={`sidebar ${isSidebarExpanded ? "expanded" : ""}`}>
            <h2 className="sidebar-header">Chat</h2>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search for a profile..."
                className="search-input"
              />
              <i className="search-icon">🔍</i>
            </div>
            <div className="conversations-list">
              {aiModels &&
                aiModels.length > 0 &&
                aiModels.map((chat) => {
                  const isActive = activeChat?.id === chat.id;
                  const isCleared = isActive && messages.length === 0; // Check if active and messages are cleared

                  return (
                    <div
                      key={chat.id}
                      className={`conversation-item ${
                        isActive ? "active" : ""
                      }`}
                      onClick={() => handleDivClick(chat)}
                    >
                      <img
                        src={chat?.avatar}
                        alt={chat?.name}
                        className="avatar"
                      />
                      <div className="conversation-details">
                        <div className="conversation-header">
                          <span className="sender-name">{chat.name}</span>
                          <span className="timestamp">
                            {chat.lastTimestamp &&
                              new Date(
                                chat.lastTimestamp.seconds * 1000
                              ).toLocaleString()}
                          </span>
                        </div>
                        <p className="message-preview">
                          {isCleared
                            ? ""
                            : chat?.lastMessage
                            ? chat.lastMessage.slice(0, 30) + "..."
                            : ""}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* Main Chat Area */}
          <div className="main-chat">
            {activeChat ? (
              <>
                <div className="chat-header">
                  <div className="chat-header-content">
                    <img
                      src={activeChat?.avatar}
                      alt={activeChat?.name}
                      className="avatar"
                    />
                    <span className="current-chat-name">{activeChat.name}</span>
                  </div>
                  <button
                    className="sidebar-toggle"
                    onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
                  >
                    {isSidebarExpanded ? "▶" : "◀"}
                  </button>
                  <button className="image-toggle" onClick={toggleImageDisplay}>
                    <Menu className="h-4 w-4" />
                  </button>
                </div>
                <div className="messages-area">
                  {messages.length > 0 ? (
                    <>
                      {isLoading && (
                        <div className={`message`}>
                          <img
                            src={activeChat?.avatar}
                            alt={activeChat?.name}
                            className="avatar"
                          />
                          <div className="message-content loading">
                            <div className="typing-indicator">
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </div>
                          </div>
                        </div>
                      )}

                      {messages.map((message) => (
                        <div
                          key={message.id}
                          className={`message ${
                            message.sender === "user" ? "user" : ""
                          }`}
                        >
                          {message.sender !== "user" && (
                            <img
                              src={activeChat?.avatar}
                              alt={activeChat?.name}
                              className="avatar"
                            />
                          )}
                          <div
                            className={`message-content ${
                              message.sender === "user" ? "user" : ""
                            }`}
                          >
                            <p>{message.content}</p>
                          </div>
                          {message.sender === "user" && (
                            <img
                              src={activeChat?.avatar}
                              alt={activeChat?.name}
                              className="avatar"
                            />
                          )}
                        </div>
                      ))}
                      <div key={1} className="message">
                        <img
                          src={activeChat?.avatar}
                          alt={activeChat?.name}
                          className="avatar"
                        />
                        <div className="message-content">
                          <p>Hey, my name is {activeChat?.name}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div key={1} className="message">
                      <img
                        src={activeChat?.avatar}
                        alt={activeChat?.name}
                        className="avatar"
                      />
                      <div className="message-content">
                        <p>Hey, my name is {activeChat?.name}</p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="no-chat-selected">
                Select a character to start chatting
              </div>
            )}
            {activeChat && (
              <div className="input-area">
                <input
                  type="text"
                  placeholder="Type a message"
                  className="message-input"
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button className="send-button" onClick={sendMessage}>
                  <Send className="h-5 w-5" />
                </button>
                <div className="input-footer">
                  <span
                    className="feedback-text"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Leave feedback or report content
                  </span>
                  <button className="reset-button" onClick={resetChat}>
                    Reset Chat
                  </button>
                </div>
              </div>
            )}
          </div>

          {isImageDisplayVisible && (
            <div className="image-display">
              <img
                src={activeChat?.avatar}
                alt={activeChat?.name}
                className="image-avatar"
              />
              <h2 className="image-name">{activeChat?.name}</h2>
              <p className="image-para">{activeChat?.description}</p>
            </div>
          )}

          {/* Modal */}
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Leave Feedback</h2>
                  <button
                    className="modal-close"
                    onClick={() => setIsModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <textarea
                  value={feedbackMessage}
                  onChange={(e) => setFeedbackMessage(e.target.value)}
                  placeholder="Write your feedback or report content..."
                  className="modal-textarea"
                />
                <div className="modal-footer">
                  <button
                    className="btn cancel"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button className="btn submit" onClick={handleFeedbackSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}

          {isSignInModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Please sign in to continue</h2>
                  <button
                    className="modal-close"
                    onClick={() => setIsSignInModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>

                <div className="modal-footer">
                  <button
                    className="btn cancel"
                    onClick={() => setIsSignInModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn submit"
                    onClick={() => router.push("/auth/signin")}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          )}
          {isSubsModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Please Subscribe to continue</h2>
                  <button
                    className="modal-close"
                    onClick={() => setIsSubsModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>

                <div className="modal-footer">
                  <button
                    className="btn cancel"
                    onClick={() => setIsSubsModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn submit"
                    onClick={() => router.push("/pricing")}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
