import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import { useAuth, requireAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import { redirectToCheckout } from "./../util/stripe";
import productsData from "../util/product_data";

function PurchasePage(props) {
  const router = useRouter();
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState();

  // useEffect(() => {
  //   if (router.query.plan in ['plus', 'premium', 'pro'] && auth.user.planIsActive) {
  //     // If user already has an active plan
  //     // then take them to Stripe billing
  //     router.push("/settings/billing");
  //   } else {
  //     // Otherwise go to checkout
  //     redirectToCheckout(router.query.plan).catch((error) => {
  //       setFormAlert({
  //         type: "error",
  //         message: error.message,
  //       });
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const { plan, amount } = router.query;

    if (["plus", "premium", "pro"].includes(plan) && auth.user?.planIsActive) {
      // If user already has an active plan, redirect to billing
      router.push("/settings/billing");
    } else {
      // Redirect to checkout with userId and amount
      redirectToCheckout(plan, auth?.user?.uid, amount).catch((error) => {
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Meta title="Purchase" />
      <PageLoader>
        {formAlert && (
          <Alert severity={formAlert.type} style={{ maxWidth: "500px" }}>
            {formAlert.message}
          </Alert>
        )}
      </PageLoader>
    </>
  );
}

export default requireAuth(PurchasePage);
