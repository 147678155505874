import { useEffect } from "react";

const StoreReferral = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ref = params.get("ref"); // Get 'ref' from URL

    if (ref) {
      // Store in Local Storage
      localStorage.setItem("tap_ref", ref);

      // Store in Cookie (expires in 365 days)
      document.cookie = `tap_ref=${ref}; path=/; max-age=31536000; Secure; SameSite=None`;
    }
  }, []);

  return null;
};

export default StoreReferral;
