import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import GeneratorSection from "./../components/GeneratorSection";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

// import GallerySection from "../components/GallerySection";
// import { requireAuth } from "./../util/auth";

function GeneratorPage(props) {
  document.title = "Create - NSFWartgenerator";
  const location = useLocation();
  const SECRET_KEY = process.env.REACT_APP_TAPFILIATE_SECRET_KEY;

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const isPaid = params.get("paid") === "true";
  //   // const userId = params.get("userId");
  //   // const amount = parseFloat(params.get("amount"));

  //   const encryptedData = params.get("data");

  //   console.log(isPaid, userId, amount);

  //   if (isPaid && userId && amount) {
  //     const script = document.createElement("script");
  //     script.src = "https://script.tapfiliate.com/tapfiliate.js";
  //     script.async = true;
  //     document.body.appendChild(script);

  //     script.onload = () => {
  //       if (window.tap) {
  //         window.tap("create", "48886-c28275", { integration: "javascript" });
  //         window.tap("conversion", userId, amount);
  //       }
  //     };

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isPaid = params.get("paid") === "true";
    const encryptedData = params.get("data");

    if (isPaid && encryptedData) {
      try {
        // Decrypt the data
        const bytes = CryptoJS.AES.decrypt(
          decodeURIComponent(encryptedData),
          SECRET_KEY
        );
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        console.log("Decrypted Data:", decryptedData);

        const { userId, amount } = decryptedData;

        if (userId && amount) {
          const script = document.createElement("script");
          script.src = "https://script.tapfiliate.com/tapfiliate.js";
          script.async = true;
          document.body.appendChild(script);

          script.onload = () => {
            if (window.tap) {
              window.tap("create", "48886-c28275", {
                integration: "javascript",
              });
              window.tap("conversion", userId, amount);
            }
          };

          return () => {
            document.body.removeChild(script);
          };
        }
      } catch (error) {
        console.error("Error decrypting data:", error);
      }
    }
  }, [location]);

  return (
    <>
      <Meta
        title="Create"
        description="Create NSFW AI Images in High Quality with just a prompt. No Limits."
      />
      <GeneratorSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Craft Your Fantasy"
        subtitle="Dive into the world of AI-driven artistry. With just a few clicks, watch your deepest desires come to life in vivid detail."
        buttonAction="Generate"
        buttonColor="primary"
      />
    </>
  );
}

export default GeneratorPage;
