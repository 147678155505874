import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Meta from "./../components/Meta";

function ThankyouPage() {
  document.title = "Thank you - NSFWartgenerator";

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://script.tapfiliate.com/tapfiliate.js";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   script.onload = () => {
  //     if (window.tap) {
  //       window.tap("create", "48886-c28275", { integration: "javascript" });
  //       window.tap("conversion", "sdfsdfs", 100);

  //     }
  //   };

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      <Helmet>
        <title>Thank You - NSFWartgenerator</title>
      </Helmet>
      <Meta
        title="Thank You"
        description="Create NSFW AI Images in High Quality with just a prompt. No Limits."
      />
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase.</p>
        <p>Your order will be processed shortly.</p>
        <button
          style={{
            cursor: "pointer",
            padding: "20px",
            borderRadius: "20px",
            border: "1px solid black",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go to Home Page
        </button>
      </div>
    </>
  );
}

export default ThankyouPage;
