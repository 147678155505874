import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getPaypalId } from "../util/prices.js";
import { apiRequest } from "../util/util.js";
import { useAuth } from "./../util/auth";
import { useRouter } from "./../util/router.js";

function PaypalSubscriptionButton(props) {
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    "enable-funding": "venmo",
    "disable-funding": "paylater,card",
    "data-sdk-integration-source": "integrationbuilder_sc",
    vault: "true",
    intent: "subscription",
  };

  const auth = useAuth();
  const router = useRouter();

  const { planId, setMessage, setErrorMessage } = props;

  const handleCreateSubscription = async () => {
    if (!auth.user) {
      router.push("/auth/signup");
    }
    try {
      const response = await fetch("/api/paypal-create-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          planId: getPaypalId(planId),
          userAction: "SUBSCRIBE_NOW",
        }),
      });
      const data = await response.json();

      if (data?.id) {
        return data.id;
      } else {
        console.error(
          { callback: "createSubscription", serverResponse: data },
          JSON.stringify(data, null, 2)
        );

        const errorDetail = data?.details?.[0];
        setErrorMessage(
          `Could not initiate PayPal Subscription...<br><br>${
            errorDetail?.issue || ""
          } ${errorDetail?.description || data?.message || ""} ` +
            (data?.debug_id ? `(${data.debug_id})` : ""),
          { hideButtons: true }
        );
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(`Could not initiate PayPal Subscription...${error}`);
    }
  };

  const handleApprove = async (data, actions) => {
    if (data.orderID) {
      setMessage(
        `You have successfully subscribed to the plan. Wait a minute for your credit to update.`
      );
      try {
        const response = await apiRequest("paypal-create-customer", "POST", {
          orderId: data.orderID,
          subscriptionId: data.subscriptionID,
        });
        const TAPFILIATE_ID = "48886-c28275";
        const script = document.createElement("script");
        script.src = "https://script.tapfiliate.com/tapfiliate.js";
        script.async = true;

        script.onload = () => {
          if (window.tap) {
            window.tap("create", TAPFILIATE_ID, { integration: "javascript" });

            const affiliateId = localStorage.getItem("tap_ref");

            const conversionData = {
              affiliate_id: affiliateId || undefined,
              amount: props.amount,
              external_id: data.orderID,
              customer_id: auth?.user?.uid,
            };

            window.tap(
              "conversion",
              conversionData.external_id,
              conversionData.amount,
              conversionData.customer_id,
              conversionData.affiliate_id
            );
          }
        };

        document.head.appendChild(script);
      } catch (error) {
        console.error(error);
        setErrorMessage(`Could not get customer data from paypal...${error}`);
      }
    } else {
      setErrorMessage(
        `Failed to activate the subscription: ${data.subscriptionID}`
      );
    }
  };

  return (
    <div className="PaypalButton" style={{ marginTop: 10 }}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createSubscription={handleCreateSubscription}
          onApprove={handleApprove}
          forceReRender={[planId]}
        />
      </PayPalScriptProvider>
    </div>
  );
}

export default PaypalSubscriptionButton;
