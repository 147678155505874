import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../util/auth";

function PaySuccessPage() {
  const location = useLocation();
  const auth = useAuth();

  // const [amount, setAmount] = useState(null);
  // const [orderId, setOrderId] = useState(null);

  const searchParams = new URLSearchParams(location.search);
  const amount = searchParams.get("amount");
  const orderId = searchParams.get("order_id");

  const TAPFILIATE_ID = "48886-c28275";

  useEffect(() => {
    // Load the Tapfiliate script dynamically
    const script = document.createElement("script");
    script.src = "https://script.tapfiliate.com/tapfiliate.js";
    script.type = "text/javascript";
    script.async = true;

    script.onload = () => {
      if (window.tap) {
        window.tap("create", TAPFILIATE_ID, { integration: "javascript" });

        // Retrieve the stored affiliate ID
        const affiliateId = localStorage.getItem("tap_ref");

        const conversionData = {
          affiliate_id: affiliateId || undefined,
          amount: amount,
          external_id: orderId,
          customer_id: auth?.user?.uid,
        };

        window.tap(
          "conversion",
          conversionData.external_id,
          conversionData.amount,
          conversionData.customer_id,
          conversionData.affiliate_id
        );
      }
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase.</p>
        <p>Your order will be processed shortly.</p>
        <button
          style={{
            cursor: "pointer",
            padding: "20px",
            borderRadius: "20px",
            border: "1px solid black",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go to Home Page
        </button>
      </div>
    </>
  );
}
export default PaySuccessPage;
