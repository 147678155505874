import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import HeroSection2 from "./../components/HeroSection2";
import HeroSection3 from "./../components/HeroSection3";
import FaqSection from "./../components/FaqSection";
import { AgeVerification } from "../components/AgeVerification";
import { useAuth } from "../util/auth";

function IndexPage(props) {
  const ageVerified = localStorage.getItem("ageVerified");
  const auth = useAuth();
  const refId = localStorage.getItem("tap_ref");

  useEffect(() => {
    if (auth?.user) {
      fetch("/api/createCustomer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ref-Id": refId || "", // Sending refId in headers
        },
        body: JSON.stringify({
          email: auth.user.email,
          name: auth.user.displayName,
        }),
      })
        .then((res) => res.json())
        .then((data) => console.log("Customer created:", data))
        .catch((err) => console.error("Error creating customer:", err));
    } else {
      console.log("notrunninfff");
    }
  }, [auth.user]);

  return (
    <>
      <Meta />
      {ageVerified !== "true" && <AgeVerification />}

      <HeroSection
        bgColor="secondary"
        size="large"
        bgImage="https://i.imgur.com/8L1xFWh.png"
        bgImageOpacity={0.2}
        title="#1 NSFW AI Generator"
        smallTitle="Bring Your Wildest Fantasies “REAL” with this Erotic, NSFW AI Generator"
        subtitle="Try our NSFW AI Art Generator to turn your fantasies into explicit artwork. Give us your prompt, and our AI will create personalized, detailed art based on your desires. Experience art in a new way and explore your fantasies visually."
        buttonText="Get Started"
        buttonColor="primary"
        buttonPath="/create"
      />
      <HeroSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Diverse Styles"
        subtitle="Our NSFW AI Art Generator allows you to create a variety of bold art styles, from anime to realistic images. Just click to explore different art styles that interest you. Use our NSFW Art Generator to find the art that matches your imagination."
        image="https://res.cloudinary.com/dvzkzccvn/images/v1710578328/desc-1-default/desc-1-default.png?_i=AA"
      />
      <HeroSection3
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Explicit Poses"
        subtitle="Our collection includes a wide range of explicit poses, from POV BlowJob to POV Spit Roast, covering various perspectives to provide a fully immersive experience. Whatever your preference, we have it covered."
        image="https://res.cloudinary.com/dvzkzccvn/images/v1710578332/desc-2-default/desc-2-default.png?_i=AA"
      />
      <HeroSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Privacy First"
        subtitle="Create NSFW art privately. Your fantasies stay secure and confidential with our discreet process, respecting your personal space."
        image="https://res.cloudinary.com/dvzkzccvn/images/v1710578340/desc-3-default/desc-3-default.png?_i=AA"
      />
      <HeroSection3
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="FREE, Fast & Reliable"
        subtitle="You can generate up to 2 images for FREE with our NSFW AI Image Generator. We use the fastest server available that results in fast generation of NSFW Images. The images are kept in a highly secured environment."
        image="https://res.cloudinary.com/dvzkzccvn/images/v1710578332/desc-2-default/desc-2-default.png?_i=AA"
      />

      <HeroSection3
        bgColor="default"
        size="medium"
        title="Why Choose Our NSFW Art Generator?"
        subtitle="Ready to dive into the endless excitement of AI NSFW art? Try our NSFW AI art generator today! It turns text into NSFW AI art in no time. There’s nothing it can’t create. Let it spark your creativity!"
        image="https://res.cloudinary.com/dvzkzccvn/images/v1710578332/desc-2-default/desc-2-default.png?_i=AA"
      />

      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
    </>
  );
}

export default IndexPage;
