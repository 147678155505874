import { loadStripe } from "@stripe/stripe-js";
import { apiRequest } from "./util";
import { getStripePriceId } from "./prices";
import CryptoJS from "crypto-js";

let stripe;
// Load the Stripe script
loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  // Pin to specific version of the Stripe API
  apiVersion: "2020-08-27",
}).then((stripeInstance) => {
  // Set stripe so all functions below have it in scope
  stripe = stripeInstance;
});

export async function redirectToCheckout(planId, userId, amount) {
  const SECRET_KEY = process.env.REACT_APP_TAPFILIATE_SECRET_KEY;
  const affiliateId = localStorage.getItem("tap_ref"); // Retrieve tap_ref

  console.log(amount, "aaaa");

  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify({ userId, amount }),
    SECRET_KEY
  ).toString();

  // Encode the encrypted data for URL safety
  const encodedData = encodeURIComponent(encryptedData);

  console.log(encodedData, "encodedddd");

  // Create a checkout session
  const session = await apiRequest("stripe-create-checkout-session", "POST", {
    priceId: getStripePriceId(planId),
    // successUrl: `${window.location.origin}/create?paid=true&`,
    successUrl: `${window.location.origin}/create?paid=true&data=${encodedData}`,
    cancelUrl: `${window.location.origin}/pricing`,
    tap_ref: affiliateId, // Pass tap_ref to API
  });
  // Ensure if user clicks browser back button from checkout they go to /pricing
  // instead of this page or they'll redirect right back to checkout.
  window.history.replaceState({}, "", "/pricing");

  // Redirect to checkout
  return stripe.redirectToCheckout({
    sessionId: session.id,
  });
}

export async function redirectToBilling() {
  const affiliateId = localStorage.getItem("tap_ref"); // Retrieve tap_ref

  // Create a billing session
  const session = await apiRequest("stripe-create-billing-session", "POST", {
    returnUrl: `${window.location.origin}/settings/general`,
    tap_ref: affiliateId,
  });

  // Ensure if user clicks browser back button from billing they go to /settings/general
  // instead of this page or they'll redirect right back to billing.
  window.history.replaceState({}, "", "/settings/general");

  // Redirect to billing session url
  window.location.href = session.url;
}
